import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1298793369/src/minisite_iovia/components/common/nav/nav_1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1298793369/src/minisite_iovia/components/common/otherComponents/contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1298793369/src/minisite_iovia/components/common/otherComponents/faq-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1298793369/src/minisite_iovia/components/common/otherComponents/form-result.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1298793369/src/minisite_iovia/components/common/otherComponents/google-play-btn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1298793369/src/minisite_iovia/components/common/otherComponents/quiz-section.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/CTA-bg.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/CTA.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/error.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/feature2-1.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/feature2-2.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/feature2-3.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/feature2-4.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/feature3.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/hero-2.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/ico2.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/public/logo.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1298793369/src/minisite_iovia/styles/globals.scss");
